
import Vue from "vue";
//mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

//dialogs
import TemporaryLogin from "@/views/dialogs/TemporaryLogin.vue";
import AttendanceFixTemporaryDialog from "@/views/dialogs/AttendanceFixTemporaryDialog.vue";
import TemporaryAttendanceDetailDialog from "@/views/dialogs/TemporaryAttendanceDetailDialog.vue";
import TemporaryShiftDialog from "@/views/dialogs/TemporaryShiftDialog.vue";
import TemporaryPasswordChangeDialog from "@/views/dialogs/TemporaryPasswordChangeDialog.vue";
import TemporaryDistanceDialog from "@/views/dialogs/TemporaryDistanceDialog.vue";
import PetitionDialog from "@/components/petition/PetitionDialogContent.vue";
import AttendanceAlert from "@/components/login-alert/AttendanceAlert.vue";
import { DateTime } from "luxon";
import { Shain1 } from "@/models/transaction/Shain1";
import OvertimeWorkAlert from "@/components/login-alert/OvertimeWorkAlert.vue";
// import ApprovalAlert from "@/components/login-alert/ApprovalAlert.vue";
// import AbsenceAlert from "@/components/login-alert/AbsenceAlert.vue";
// import LeaveEarlyAlert from "@/components/login-alert/LeaveEarlyAlert.vue";
// import LegalHolidayAlert from "@/components/login-alert/LegalHolidayAlert.vue";
// import AbsentAlert from "@/components/login-alert/AbsentAlert.vue";
import SearchShiftDialog from "@/components/approval/shift/SearchShiftDialog.vue";

const DATE = new Date();

const Actions = {
  Eat: 0,
  Break: 1,
  MidBreak: 2,
  Fix: 3,
  Roster: 4,
  Shift: 5,
  Password: 6,
  WelfareMove: 7
} as const;
type ActionsValueType = typeof Actions[keyof typeof Actions];

export default Vue.extend({
  name: "Office",
  mixins: [Common, ShowDialogs, Api],
  components: {
    PetitionDialog,
    AttendanceAlert,
    OvertimeWorkAlert,
    // ApprovalAlert,
    // AbsenceAlert,
    // LeaveEarlyAlert,
    // LegalHolidayAlert,
    // AbsentAlert,
    SearchShiftDialog
  },
  data: () => ({
    // 画面 調整用
    width: window.innerWidth,
    height: window.innerHeight,
    //
    isDialog: false,
    date: DATE,
    previousDate: "",
    targetdate: `${DATE.getFullYear()}-${DATE.getMonth() +
      1}-${DATE.getDate()}`,
    Actions,
    selectShozoku: "",
    shozokuList: [],
    shozokuListReadOnly: false,
    disp: "",
    employeeId: undefined as number | undefined,
    affiliationId: undefined as number | undefined,
    weekday: "",
    prevWeekday: "",
    week: ["日", "月", "火", "水", "木", "金", "土"],
    isShiftTable: false,
    attendancevisible: true,
    timeout: 0,
    isNightShift: 0
  }),
  watch: {
    selectShozoku: {
      handler(newValue: number) {
        // 事業所選択の変更時
        this.$store.state.workIncode = newValue;
        this.$store.state.supplement = "";
      }
    },
    attendancevisible: {
      handler(newvalue: boolean) {
        console.log("attendancevisible", newvalue);
        if (!this.attendancevisible) {
          console.log("timeout start");
          this.timeout = setTimeout(() => {
            if (!this.attendancevisible) {
              this.employeeId = undefined;
            }
          }, 30000);
        } else {
          if (this.attendancevisible) {
            clearTimeout(this.timeout);
            console.log("timeout stop");
          }
        }
      }
    },
    absencevisible: {
      handler(newvalue: boolean) {
        console.log("absencevisible", newvalue);
        this.attendancevisible = newvalue;
      }
    },
    leavewatch: {
      handler(newvalue: boolean) {
        console.log("leavewatch", newvalue);
        this.attendancevisible = newvalue;
      }
    },
    absentvisible: {
      handler(newvalue: boolean) {
        console.log("absentvisible", newvalue);
        this.attendancevisible = newvalue;
      }
    },
    legalwatch: {
      handler(newvalue: boolean) {
        console.log("legalwatch", newvalue);
        this.attendancevisible = newvalue;
      }
    }
  },
  methods: {
    isShow() {
      // if (this.$store.state.shozokuCode1 === BusyoCode.WELFARE) {
      //   return true;
      // }
      return false;
    },
    async click(action: ActionsValueType) {
      if (!this.selectShozoku) {
        this.$error("事業所を選択してください！");
        return;
      }
      this.isNightShift = 0;
      if (!this.$store.state.user.login) {
        const result = await this.$showDialog(TemporaryLogin);
        console.log("qrResult", result);

        if (!result) return;
      }

      switch (action) {
        case Actions.Fix:
          await this.fix();
          break;
        case Actions.Roster:
          await this.roster();
          break;
        case Actions.Shift:
          await this.shift();
          break;
        case Actions.Password:
          await this.pwdchange();
          break;
        case Actions.WelfareMove:
          await this.setdistance();
          break;
      }
    },
    async onPetition() {
      if (!this.selectShozoku) {
        this.$alert("warning", "事業所を選択してください！");
        return;
      }
      this.isNightShift = 0;
      if (!this.$store.state.user.login) {
        const result = await this.$showDialog(TemporaryLogin);
        if (!result) return;
      }
      // this.isDialog = true;
      this.$router.push("alertview");
    },
    async openShiftTable() {
      if (!this.selectShozoku) {
        this.$alert("warning", "事業所を選択してください！");
        return;
      }
      this.isNightShift = 0;
      if (!this.$store.state.user.login) {
        const result = await this.$showDialog(TemporaryLogin);
        if (!result) return;
      }
      this.isShiftTable = true;
    },
    onClose() {
      this.$store.commit("user/logout");
    },
    async fix() {
      await this.updateDate();
      const ts = this.date.getTime();
      const param = {
        // incode: this.$store.state.incode,
        incode: this.selectShozoku,
        userincode: this.$store.state.user.incode,
        targetdate: this.targetdate
      };

      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);

      const resp = await this.$get<any>(
        this.Paths.attendance,
        "temporary=" + encode
      );

      const args: any = {
        recordid: resp.recordid ? resp.recordid : "",
        employee: resp.employee ? resp.employee : this.$store.state.user.incode,
        affiliation: resp.affiliation ? resp.affiliation : this.selectShozoku,
        shiftid: resp.shiftid ? resp.shiftid : "",
        shiftpatternid: resp.shiftpatternid ? resp.shiftpatternid : "",
        datestr: this.targetdate,
        date: ts,
        start: resp.start ? resp.start : "",
        end: resp.end ? resp.end : "",
        distance: resp.distance ? resp.distance : "0",
        inover: resp.inover ? resp.inover : "",
        lateleave: resp.lateleave ? resp.lateleave : "",
        amount: resp.amount ? resp.amount : "",
        breakflg: resp.breakflg ? resp.breakflg : "",
        daycount: resp.daycount ? resp.daycount : "",
        nightcount: resp.nightcount ? resp.nightcount : "",
        hadmeal: resp.hadmeal ? resp.hadmeal : "0",
        careShu: resp.careShu ? resp.careShu : "0",
        careKbn: resp.careKbn ? resp.careKbn : "0",
        timeKbn: resp.timeKbn ? resp.timeKbn : "0",
        assistFlag: resp.assistFlag ? resp.assistFlag : "0",
        otherFlag: resp.otherFlag ? resp.otherFlag : "0"
      };
      // 打刻時間を追加
      args.stampTime = resp.stampTime ? resp.stampTime : "";
      await this.$showDialog(AttendanceFixTemporaryDialog, { args });
    },
    async roster() {
      await this.updateDate();
      const param = {
        // incode: this.$store.state.incode,
        incode: this.selectShozoku,
        userincode: this.$store.state.user.incode,
        targetdate: this.targetdate
      };

      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      try {
        const item = await this.$get(
          this.Paths.attendance,
          "tmpattendance=" + encode
        );
        await this.$showDialog(TemporaryAttendanceDetailDialog, { args: item });
      } catch (e) {
        this.errorHandler(e);
        this.$store.commit("user/logout");
      }
    },
    async shift() {
      const a = DateTime.now();
      const b = DateTime.now().set({ day: 1 });
      // if (a >= b) {
      //   a.plus({ month: 1 });
      // }
      const date = a;
      //const date = this.$getPmServiceDate();

      const param = {
        userincode: this.$store.state.user.incode,
        targetmonth: date.toFormat("yyyy-MM")
        //parseInt(date.split("-")[0]) + "-" + parseInt(date.split("-")[1])
      };

      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);

      try {
        const item = await this.$get(this.Paths.shift, "tmpshift=" + encode);
        await this.$showDialog(TemporaryShiftDialog, { args: item });
      } catch (e) {
        this.errorHandler(e);
        this.$store.commit("user/logout");
      }
    },
    async pwdchange() {
      // パスワード変更は不使用
      try {
        const param = {
          userincode: this.$store.state.user.incode,
          usercode: this.$store.state.user.code
        };
        await this.$showDialog(TemporaryPasswordChangeDialog, { args: param });
      } catch (e) {
        this.errorHandler(e);
        this.$store.commit("user/logout");
      }
    },
    async setdistance() {
      // 移動登録は不使用
      try {
        const queryparam = {
          userincode: this.$store.state.user.incode,
          targetdate: this.targetdate
        };
        const encode = encodeURI(JSON.stringify(queryparam));
        const records = await this.$get<any>(
          this.Paths.tmpDistance,
          "query=" + encode
        );

        const param = {
          userincode: this.$store.state.user.incode,
          usercode: this.$store.state.user.code,
          dataheaders: records.headers,
          databodies: records.body
        };
        await this.$showDialog(TemporaryDistanceDialog, { args: param });
      } catch (e) {
        this.errorHandler(e);
        this.$store.commit("user/logout");
      }
    },
    async setAttendance(isEnd = false) {
      // 出退勤メッセージクリア
      this.$store.state.supplement = "";
      if (!this.selectShozoku) {
        this.$error("事業所を選択してください！");
        return;
      }
      // ダイアログ表示後に所属選択を初期化。登録はthis.$store.state.workIncodeから取得。
      // ただし、1件のみの場合は初期化を行わない。
      if (this.shozokuList.length > 1) this.selectShozoku = "";

      try {
        const title = !isEnd ? "出勤" : "退勤";
        const path = !isEnd
          ? this.Paths.attendanceStart
          : this.Paths.attendanceEnd;

        const qr = await this.$dialog("qr-code-reader", title);
        const selectedIsNightShiftValue = this.isNightShift;
        this.isNightShift = 0;
        if (qr) {
          const split = qr.split("@");
          const data = {
            loginId: split[0],
            password: split[1],
            shozokuIncode: this.$store.state.workIncode,
            isNightShift: selectedIsNightShiftValue
          };
          if (!isEnd) {
            const encode = encodeURI(JSON.stringify(data));
            const check = await this.$get(
              this.Paths.attendanceCheck,
              "query=" + encode
            );
            console.log(check, "check");
            if (check === true) {
              console.log("check_true");
              const message = "本日この現場では、既に出勤しています。";
              await this.$error(message);
              return;
            } else if (check != false) {
              // errorで返ってきたとき
              this.$error(
                "エラーが発生しました。\n 読取QRが正しいことを確認ください。"
              );
            }
          } else {
            // 退勤打刻時
            const encode = encodeURI(JSON.stringify(data));
            const check = await this.$get(
              this.Paths.attendanceCheckEnd,
              "query=" + encode
            );
            if (check === true) {
              const message = "今日はまだ出勤がありません。\n退勤しますか？";
              const check = await this.$confirm(message);
              if (!check) return;
            } else if (check != false) {
              // errorで返ってきたとき
              this.$error(
                "エラーが発生しました。\n 読取QRが正しいことを確認ください。"
              );
            }
          }

          const result = await this.$post<any>(path, data);

          //アラート確認
          const shain = await this.$post<Shain1>(
            this.Paths.authTemporary,
            data
          );
          if (shain != null) {
            this.employeeId = shain.incode;
            this.affiliationId = this.$store.state.workIncode;

            await this.settimeoutflag();

            if (result) {
              this.disp = " " + result.displaymessage;
            }
            // 認証ごとにクローズし、親画面で所属を選びなおす。
            this.$info(result.supplement);
            // this.$store.state.supplement = result.supplement;
          } else {
            this.$error(
              "予期せぬエラーが発生しました。\nログインから再度試してください"
            );
            this.$store.commit("logout");
            this.$store.commit("user/logout");
            this.$router.push({ name: "Login" });
          }
        }
      } catch (error) {
        const e = error as any;
        this.$error(e.message);
        if (e.message === "認証トークンの有効期限切れです") {
          this.$store.commit("logout");
          this.$store.commit("user/logout");
          this.$router.push({ name: "Login" });
        }
      }
    },
    async updateDate() {
      this.date = new Date();
      // 曜日情報を作成
      const w = this.date.getDay();
      console.log(this.date.getDay());
      this.weekday = "(" + this.week[w] + ")";
      this.targetdate = `${this.date.getFullYear()}-${this.date.getMonth() +
        1}-${this.date.getDate()}`;
    },
    async setShozokuList() {
      try {
        const queryparam = { incode: this.$store.state.incode };
        const encode = encodeURI(JSON.stringify(queryparam));
        const records = await this.$get<any>(
          this.Paths.shozokuGroup,
          "query=" + encode
        );
        this.shozokuList = records;
        this.$store.state.workIncode = "";
        if (records.length === 1) {
          this.shozokuListReadOnly = true;
          this.selectShozoku = records[0].incode;
          this.$store.state.workIncode = records[0].incode;
        }
      } catch (error) {
        const e = error as any;
        console.log(e);
        this.$error(e.message);
        this.$store.commit("user/logout");
      }
    },
    async settimeoutflag() {
      if (!this.attendancevisible) this.attendancevisible = true;
      this.attendancevisible = false;
      console.log("attendancevisible", this.attendancevisible);
      // if (!this.absencevisible) this.absencevisible = true;
      // this.absencevisible = false;
      // if (!this.leavevisible) this.leavevisible = true;
      // this.leavevisible = false;
      // if (!this.absentvisible) this.absentvisible = true;
      // this.absentvisible = false;
      // if (!this.legalvisible) this.legalvisible = true;
      // this.legalvisible = false;
    },
    handleResize() {
      // resize 発火
      console.log("handleResize");
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    errorHandler(e: any) {
      if (
        e instanceof Object &&
        "message" in e &&
        typeof e.message === "string"
      ) {
        this.$error(e.message);
      } else {
        this.$error("不明なエラーです");
      }
    },
    async absencewatch(value: boolean) {
      console.log("absencewatch", value);
      this.attendancevisible = value;
    },
    async attendancewatch(value: boolean) {
      console.log("attendancewatch", value);
      this.attendancevisible = value;
    },
    async leavewatch(value: boolean) {
      console.log("leavewatch", value);
      this.attendancevisible = value;
    },
    async absentwatch(value: boolean) {
      console.log("absentwatch", value);
      this.attendancevisible = value;
    },
    async legalwatch(value: boolean) {
      console.log("legalwatch", value);
      this.attendancevisible = value;
    },
    async overtimewatch(value: boolean) {
      console.log("overtimewatch", value);
      this.attendancevisible = value;
    }
  },
  async created() {
    console.log("created");
    // 曜日情報を作成
    const w = this.date.getDay();
    console.log(this.date.getDay());
    this.weekday = "(" + this.week[w] + ")";
    this.$store.commit("user/logout");
    await this.setShozokuList();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    setInterval(() => {
      const w = this.date.getDay();
      console.log("day", this.date.getDay());
      console.log("width", this.width, "height", this.height);
      this.weekday = "(" + this.week[w] + ")";
      this.prevWeekday = "(" + this.week[w > 0 ? w - 1 : 6] + ")";
      this.date = new Date();
      this.previousDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() - 1
      ).toLocaleDateString();
    }, 500);
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  }
});
