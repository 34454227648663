const AlertMessages = {
  Attendance: "打刻漏れです。勤務時間変更申請を申請ボタンからしてください。",
  Overtime: "時間超過です。 延長申請を申請ボタンからしてください。",
  Absence: "遅刻しています。勤務時間変更申請画面で申請してください。",
  LeaveEarly: "早退しています。勤務時間変更申請画面で申請してください。",
  LegalHoliday:
    "公休出勤しています。正しい予定にシフトを変更してください。\n※17日までに申請が無い場合は、給与計算ができません。\n※公休とはシフト表にて出勤日とされていない日のこと ",
  Absent:
    "出勤日に打刻がありません。休暇申請画面で申請してください。\n※17日までに申請が無い場合は、欠勤として処理します。"
} as const;

const OvertimeHintMessages = {
  EndTime: "退勤時刻を入力してください。",
  BreakTime: "延長した時間で取った休憩時間を入力してください。"
};

const Form = {
  Validate: "入力に誤りがあります。\n入力内容を確認してください。"
} as const;

const Success = {
  Regist: "登録に成功しました。",
  Update: "更新に成功しました。",
  Delete: "削除に成功しました。"
} as const;

const Fail = {
  Regist: "登録に失敗しました。",
  Update: "更新に失敗しました。",
  Delete: "削除に失敗しました。"
} as const;

export const Messages = {
  Alert: AlertMessages,
  OvertimeHint: OvertimeHintMessages,
  Form,
  Success,
  Fail
} as const;
